import "./App.scss";
import { useMemo } from "react";

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider, Grid, Container } from "@material-ui/core";


const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    palette: {
        type: 'light',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
      <ThemeProvider theme={theme}>
                <div className="flex-grid">
                    <div className="col two">
                      <h1>Tree Rings</h1>
                    </div>
                    <div className="col two right-center">
                     {/* <div className='section selected'>Tree Rings</div> */} 
                    </div>
                </div>
                <div className="flex-grid">
                    <div className="col one">
                      <h2>An aging NFT</h2>
                      <p className="two-thirds">Physical objects get old. Should digital ones? Tree Rings is a limited set of 108 NFTs on the Solana blockchain that age over time. Each tree adds rings and ages in hours, years, or millennia.</p>
                    </div>
                </div>
                <div className="flex-grid no-gap">
                    <div className="col three">
                      <iframe src = "early-age.html"></iframe>
                      <div className="iframe-label">
                          Today
                      </div>
                      </div>
                    <div className="col three">
                      <iframe src = "mid-age.html"></iframe>
                      <div className="iframe-label">
                          ... in 7 years
                      </div>
                    </div>
                    <div className="col three">
                      <iframe src = "late-age.html"></iframe>
                      <div className="iframe-label">
                          ... in 50+ years
                      </div>
                    </div>
                </div>
                <div className="center-band">
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect={true}>
                    <WalletDialogProvider>
                      <Home
                        candyMachineId={candyMachineId}
                        config={config}
                        connection={connection}
                        startDate={startDateSeed}
                        treasury={treasury}
                        txTimeout={txTimeout}
                      />
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
                </div>
                <div className="flex-grid">
                    <div className="col one">
                      <h2>Attributes</h2>
                      <p>A unique set of characteristics determine how a given tree grows and at what rate. <br/>
                      An early mint: <a href="https://explorer.solana.com/address/3ibRoFcY87ur3wsbuB38L5bKXXvDcqYgJHpTbjwb34e7" target="_blank">https://explorer.solana.com/address/3ibRoFcY87ur3wsbuB38L5bKXXvDcqYgJHpTbjwb34e7</a></p>
                    </div>
                </div>
                <div className="flex-grid">
                <div className="col one">
                  <div className="flex-grid wrap">

                  <div className="col four">
                    <img src="location.png"></img>
                    <div className="iframe-label">
                         Location
                      </div><p>Determines the overall style of the tree. The most rare is Plenteark, illustreated across 4% of all NFTs.</p></div>
                    <div className="col four">
                    <img src="bark.png"></img>
                      <div className="iframe-label">
                          Bark
                      </div>
                      <p>The pattern with which the tree grows. The most rare is spiky, illustated across 5% of all NFTs.</p></div>
                    <div className="col four">
                    <img src="cycle.png"></img>
                      <div className="iframe-label">
                          Cycle
                      </div>
                      <p>The rate at which the tree adds rings and ages. The most rare is days, illustated across 6% of all NFTs.</p>
                      </div>
                    <div className="col four">
                    <img src="eye.png"></img>
                      <div className="iframe-label">
                          Eye
                      </div> <p>The center of the tree. The most rare is a cat eye, illustated across 3% of all NFTs.</p></div>

                  </div>
                
                </div>
                </div>
                <div className='footer'><a href="mailto:hi@treerings.art">hi@tree-rings-art.com</a></div>
                
      </ThemeProvider>
  );
};

export default App;
